import { useQuery } from '@tanstack/react-query';
import AddIcon from '@mui/icons-material/Add';
import { Autocomplete, Button, Paper, Stack, TextField, Typography } from '@mui/material';
import { useDebouncedQuery } from '@qb/frontend/hooks/useDebouncedQuery';
import { spaQueries } from '@qb/httpRequest/spaQueries';
import { ContactAutocompleteResponse } from '@/shared/types/controllers/SearchControllerTypes';
import { isString } from '@/shared/types/util/TypeGuards';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
const CHARACTER_THRESHOLD = 1;
type Contact = ContactAutocompleteResponse['contacts'][0];
type ContactAutocompleteProps = {
  selectedContact: Contact | null;
  setSelectedContact: (contact: Contact | null) => void;
  openAddNewCustomerDrawer: () => void;
};
export const ContactAutocomplete = ({
  selectedContact,
  setSelectedContact,
  openAddNewCustomerDrawer
}: ContactAutocompleteProps) => {
  const tenantAlias = useTenantAlias();
  const {
    query,
    setQuery,
    debouncedQuery
  } = useDebouncedQuery('');
  const enabled = debouncedQuery.trim().length >= CHARACTER_THRESHOLD;
  const {
    data: contacts = [],
    isFetching
  } = useQuery({
    ...spaQueries.search.contactAutocomplete({
      query: debouncedQuery
    }, tenantAlias),
    enabled,
    select: data => data.contacts
  });
  return <Autocomplete inputValue={query} freeSolo onInputChange={(_event, newInputValue) => {
    if (newInputValue.trim().length === 0) {
      setSelectedContact(null);
    }
    setQuery(newInputValue);
  }} onChange={(_event, newValue) => {
    if (isContact(newValue)) {
      setSelectedContact(newValue);
    }
  }} value={selectedContact ? `${selectedContact.firstName} ${selectedContact.lastName}` : ''} disableClearable multiple={false} options={contacts} filterOptions={options => options} renderInput={params => <TextField {...params} label="Enter name, e-mail, company name" />} loading={isFetching} getOptionLabel={option => isString(option) ? option : getContactFullName(option)} renderOption={(props, option) => {
    // @ts-expect-error - have to delete key from props because we can't spread it, but TS claims that it doesn't exist on props
    const {
      key,
      ...optionProps
    } = props;
    return <Stack justifyContent="center" direction="row" component="li" gap={2} key={key + option.email} px={2} py={1} {...optionProps}>
            <Typography variant="h6" sx={{
        flex: 1
      }} noWrap>
              {option.companyName}
            </Typography>
            <Typography variant="bodyMedium" sx={{
        flex: 1
      }} noWrap>
              {option.firstName} {option.lastName}
            </Typography>
            <Typography variant="bodyMedium" sx={{
        flex: 1
      }} noWrap>
              {option.email}
            </Typography>
          </Stack>;
  }} PaperComponent={({
    children
  }) => <Paper square sx={{
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  }}>
          {children}
          <Button variant="text"
    // onMouseDown required because it precedes onBlur
    onMouseDown={() => {
      openAddNewCustomerDrawer();
    }} sx={{
      ml: 1,
      mb: 0.5
    }} startIcon={<AddIcon />}>
            Add new customer
          </Button>
        </Paper>} data-sentry-element="Autocomplete" data-sentry-component="ContactAutocomplete" data-sentry-source-file="ContactAutocomplete.tsx" />;
};
const isContact = (contact: unknown): contact is Contact => {
  return typeof contact === 'object' && contact !== null && 'firstName' in contact && 'lastName' in contact;
};
const getContactFullName = (contact: Contact) => `${contact.firstName} ${contact.lastName}`;